import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface UserProps {
  isVerified: boolean;
  accessToken: string;
  redirectUri: string;
  isDeviceManagement: boolean;
  fingerprintId: string;
  userId: string;
}

export interface UserStateProps {
  users: UserProps[];
}

export const initialState: UserStateProps = {
  users: [],
};

export const userSlice = createSlice({
  name: "usersCollection",
  initialState,
  reducers: {
    createUser: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      const userExists = state.users.some((user) => user.userId === userId);
      if (!userExists) {
        const newUser: UserProps = {
          isVerified: false,
          accessToken: "",
          fingerprintId: "",
          redirectUri: "",
          isDeviceManagement: false,
          userId,
        };
        state.users.push(newUser);
      }
    },
    patchUser: (
      state,
      action: PayloadAction<{ userId: string; patchData: Partial<UserProps> }>
    ) => {
      const { userId, patchData } = action.payload;
      const userIndex = state.users.findIndex((user) => user.userId === userId);
      if (userIndex !== -1) {
        state.users[userIndex] = { ...state.users[userIndex], ...patchData };
      }
    },
    putUser: (state, action: PayloadAction<UserProps>) => {
      const newUser = action.payload;
      const userIndex = state.users.findIndex(
        (user) => user.userId === newUser.userId
      );
      if (userIndex !== -1) {
        state.users[userIndex] = newUser;
      }
    },
    deleteUser: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      const userIndex = state.users.findIndex((user) => user.userId === userId);
      if (userIndex !== -1) {
        state.users.splice(userIndex, 1);
      }
    },
  },
});

export const { createUser, patchUser, putUser, deleteUser } = userSlice.actions;

export const selectUser = (state: RootState, userId: string): UserProps => {
  let user = state.usersCollection.users.find((user) => user.userId === userId);
  if (!user) {
    user = {
      userId,
      isVerified: false,
      accessToken: "",
      fingerprintId: "",
      redirectUri: "",
      isDeviceManagement: false,
    };
  }
  return user;
};

export default userSlice.reducer;
