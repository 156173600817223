/** @format */

import * as R from "ramda";

export const camelToSnack = (str) => {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const snackToCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, function ($1) {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

var isArray = function isArray(a) {
  return Array.isArray(a);
};

var isObject = function isObject(o) {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};

export const keysToCamel = (o) => {
  if (isObject(o)) {
    var n = {};

    Object.keys(o).forEach(function (k) {
      n[snackToCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map(function (i) {
      return keysToCamel(i);
    });
  }

  return o;
};

export const keysToSnack = (o) => {
  if (isObject(o)) {
    var n = {};

    Object.keys(o).forEach(function (k) {
      n[camelToSnack(k)] = keysToSnack(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map(function (i) {
      return keysToSnack(i);
    });
  }

  return o;
};

export const objToQuery = R.curry(function (transfer, obj) {
  return Object.keys(obj)
    .filter(function (key) {
      return !R.isNil(obj[key]);
    })
    .map(function (key) {
      return transfer(key) + "=" + obj[key];
    })
    .join("&");
});
export const firstUpperCase = (str) => {
  return str.toString()[0].toUpperCase() + str.toString().slice(1);
};
export const auid = (domain, type, name) =>
  `auid_${domain}_${type}_${name}`.toLowerCase();
export const auidItem = (name) => auid("form", "item", name);
export const auidInput = (name) => auid("form", "txt", name);
export const auidSelect = (name) => auid("form", "dd", name);
export const auidOption = (name, index) =>
  auid("form", "dd", `${name}_option${index}`);

export function stringToHslColor(str, saturation, lightness) {
  if (!str) {
    return "hsl(0, 0%, 93%)";
  }
  var hash = 0;
  for (var i = 0; i < (str || "").length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return "hsl(" + h + ", " + saturation + "%, " + lightness + "%)";
}
